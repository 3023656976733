import React,{useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import "../../css/news.css" ;
import silmasRoute from '../../constants/SilmasRoutes';

import {
    CContainer, 
   CRow,         
   CCol,
   CCardImage,
   CCardTitle,
   CCardText,
   CCardBody,
  } from '@coreui/react';
import '@coreui/coreui/dist/css/coreui.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import db, {CollectionName } from '../../firebase/firebase';

import { collection, doc, getDoc, getDocs } from 'firebase/firestore';
//import SimpleDateTime  from 'react-simple-timestamp-to-date';


const Bloc =({news})=>{  

    let img ;
    if(news.image){     
      img= <CCardImage className="d-block d-block-C w-100 img_new" src={news.image}  animation="glow" />
    }
    //console.log('les images', img)
     
    return(
        <CCol  className="new_item">
            {img}
            <CCardBody>
                
                <CCardText>
                    {news.titre}
                </CCardText>
            </CCardBody>
        </CCol>
             )} ;
const News = () => {

  const [newData, setnewData]=useState([]);
    useEffect(()=>{
      getNew();
    },[])
    const getNew= async()=>{
      const query = collection(db,CollectionName.NEW);
      const newlist= await getDocs(query);
      let data= newlist.docs.map(doc => doc.data());
      ////console.log('from fire',data);
      setnewData(data);
    }
    let newlistview = newData.map(news =>(<Bloc news={news} />));
    //  console.log('ma liste', newlistview)

  return (
        <div className="new_main_container">
          <h1>Nos dernieres nouvelles</h1>
          <div className='new_container' > 
             <div className='new_container_1' >
                 {newlistview}
              </div>
             <div className='new_container_2'>
               <CCardBody >
                    <CCardTitle>LES COMMUNIQUES DE PRESSES</CCardTitle>
                    <CCardText>
                       SILMA SAS est une entreprise HighTech 
                       qui intervient dans les installations 
                       réseaux, les maisons connectées 
                       et le développement logiciels.
                   </CCardText>
                </CCardBody>
             </div>
          </div> 
         <div className="v_plus"> <Link className="v_plus_link" to={silmasRoute.galeries}>Voir Plus</Link> </div>
        </div>
    );
};
export default News;