import React,{useState, useEffect} from 'react';
import News from '../components/Home/New.js';
import Statistique from '../components/Home/Statistique';
import ActuCarou from '../components/Home/ActuCarou';
import '../css/Home/home.css' ;
import db from '../firebase/firebase'

import { CollectionName } from '../firebase/firebase';
import {query, orderBy, collection, addDoc, getDocs } from "firebase/firestore"; 
import Header from '../components/Header';
import '@coreui/coreui/dist/css/coreui.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Footer from '../components/Footer';
import SilmasVision from '../components/SilmasVision';
import img1 from '../assets/pub1_in.jpeg' ;

const BlocSolution =({solution})=>{
   let backgroun ;
   let color ;
     if (solution.id===1) {
      backgroun='#1959DB' ;
      color='#fff';
     } else if(solution.id===2) {
      backgroun='#b71540' ;
     }else if(solution.id===3){
      backgroun='#F55023' ;
     }else if(solution.id===4){
      backgroun='#021438' ;
     color='#fff';
     }
    const stylebloc={
       backgroundColor:backgroun ,
       color:color ,
      }

    const image_bac=(<div className='solution_part_one' 
                                  style={{backgroundImage: `url(${solution.image})`}}>
                     </div>) ;
    const title_container =(<div className='solution_part_two' style={stylebloc} >
                                <h1>{solution.title} </h1>
                                <p>{solution.description} </p>
                           </div>)  ;         
      let view_one;
      let view_two ;
      let st ;
      switch (solution.id) {
        case 1:
          view_one=image_bac ;
          view_two=title_container ;
          st={backgroundColor:'#b71540'}  
          
          break;
        case 2 :
          view_one=title_container ;
          view_two= image_bac;
          st={backgroundColor:'#b71540'}  
         
          break ;
        case 3:
          view_one=image_bac ;
          view_two=title_container ;
          st={backgroundColor:'#b71540'}  
         
          break;
         case 4 :
          view_one=title_container ;
          view_two= image_bac;
          break;

        default:
          view_one=image_bac ;
          view_two=title_container ;
          break;
      }
       
  return (
    <div className='solution_container'>
         {view_one}
         {view_two}                          
    </div>
  )
}
const Home = () => {
    const [solutions, setsolutions] = useState([]) ;
    const getSolution=async ()=>{
    const c =collection(db,CollectionName.SOLUTIONS);
    let q= query(c,orderBy('id',"desc")) ;
    const serviceList= await getDocs(q);
    let data=serviceList.docs.map(doc => doc.data()) ;    
   setsolutions(data);
  } 
  
  useEffect(() => {
    getSolution();
  })
    let img_localisation =require('../assets/localisation.jpg') ;

    let img_geolocalisation =require('../assets/Geolocalisation .jpg') ;
    let img_formation =require('../assets/Formation.jpg') ;
    let img_domotique =require('../assets/Domotique.jpg') ;
    let img_solution_IT=require('../assets/Solution_IT.jpg') ;

     let solutionList =solutions.map(s=><BlocSolution solution={s}/>) ;
     
    return (
    <div  className='home_body' >
        <Header/>
        <div className='body_container_main'>             
          <div className='actuCarou'>      
             <ActuCarou/>
             <hr/>            
           </div>           
           <h1 className='title_inter_bloc title_separator1'>Nos solutions technologique</h1>
           <div className='solution_main_container'>
               {/*solutionList*/}
               <BlocSolution solution={{id:1,image:img_domotique,title:'Electronic & domotique',description:'description'}}/>
               <BlocSolution solution={{id:2,image:img_localisation,title:'Securité & géolocation',description:'description'}}/>
               <BlocSolution solution={{id:3,image:img_solution_IT,title:'Soluion IT',description:'description'}}/>
               <BlocSolution solution={{id:4,image:img_formation,title:'Formation',description:'description'}}/>
           </div> 
           <SilmasVision/>                  
           <News/>
           <hr/>
          {/* <Statistique/>*/}
           <Footer/>
          </div>
   </div>
    );
};
export default Home;