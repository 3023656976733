import React,{useState, useEffect} from 'react';
import '../../css/Home/home.css' ;
import {CChartLine} from '@coreui/react-chartjs';
import CIcon from '@coreui/icons-react';
import { cilList, cilShieldAlt, cibFacebook, cibTwitter } from '@coreui/icons';
import '@coreui/coreui/dist/css/coreui.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import {
   CCarousel, 
   CRow,         
   CCol,
   CWidgetStatsD,
   CButton
  } from '@coreui/react';

  const Statistique = () => {
    return(
      <CCarousel className='crow_marg'>
      <CRow >
      <CCol xs={3}>
        <CWidgetStatsD
          className="mb-3"
          icon={<CIcon className="my-4 text-white CCol_colore" icon={cibFacebook} height={52} />}
          chart={
            <CChartLine
              className="position-absolute w-100 h-100 CCol_colorer"
              data={{
                labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
                datasets: [
                  {
                    backgroundColor: 'rgba(255,255,255,.1)',
                    borderColor: 'rgba(255,255,255,.55)',
                    pointHoverBackgroundColor: '#fff',
                    borderWidth: 2,
                    data: [65, 59, 84, 84, 51, 55, 40],
                    fill: true,
                  },
                ],
              }}
              options={{
                elements: {
                  line: {
                    tension: 0.4,
                  },
                  point: {
                    radius: 0,
                    hitRadius: 10,
                    hoverRadius: 4,
                    hoverBorderWidth: 3,
                  },
                },
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    display: false,
                  },
                },
                scales: {
                  x: {
                    display: false,
                  },
                  y: {
                    display: false,
                  },
                },
              }}
            />
          }
          style=  {{}}
          values={[
            { title: 'friends' } ,
            { title: 'feeds',  } ,
          ]}
        />
      </CCol>

      <CCol xs={3}>
        <CWidgetStatsD
          className="mb-3"
          icon={<CIcon className="my-4 text-white CCol_colore" icon={cibTwitter} height={52} />}
          chart={
            <CChartLine
              className="position-absolute w-100 h-100 CCol_colorer1"
              data={{
                labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
                datasets: [
                  {
                    backgroundColor: 'rgba(255,255,255,.1)',
                    borderColor: 'rgba(255,255,255,.55)',
                    pointHoverBackgroundColor: '#fff',
                    borderWidth: 2,
                    data: [1, 13, 9, 17, 34, 41, 38],
                    fill: true,
                  },
                ],
              }}
              options={{
                elements: {
                  line: {
                    tension: 0.4,
                  },
                  point: {
                    radius: 0,
                    hitRadius: 10,
                    hoverRadius: 4,
                    hoverBorderWidth: 3,
                  },
                },
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    display: false,
                  },
                },
                scales: {
                  x: {
                    display: false,
                  },
                  y: {
                    display: false,
                  },
                },
              }}
            />
          }
          style={{ '--cui-card-cap-bg': '#00aced' }}
          values={[
            { title: 'folowers', value: '973K' },
            { title: 'tweets', value: '1.792k' },
          ]}
        />
      </CCol>
      <CCol xs={3}>
        <CWidgetStatsD
          className="mb-3"
          icon={<CIcon className="my-4 text-white CCol_colore" icon={cibTwitter} height={52} />}
          chart={
            <CChartLine
              className="position-absolute w-100 h-100 CCol_colorer2"
              data={{
                labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
                datasets: [
                  {
                    backgroundColor: 'rgba(255,255,255,.1)',
                    borderColor: 'rgba(255,255,255,.55)',
                    pointHoverBackgroundColor: '#fff',
                    borderWidth: 2,
                    data: [1, 13, 9, 17, 34, 41, 38],
                    fill: true,
                  },
                ],
              }}
              options={{
                elements: {
                  line: {
                    tension: 0.4,
                  },
                  point: {
                    radius: 0,
                    hitRadius: 10,
                    hoverRadius: 4,
                    hoverBorderWidth: 3,
                  },
                },
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    display: false,
                  },
                },
                scales: {
                  x: {
                    display: false,
                  },
                  y: {
                    display: false,
                  },
                },
              }}
            />
          }
          style={{ '--cui-card-cap-bg': '#00aced' }}
          values={[
            { title: 'folowers', value: '973K' },
            { title: 'tweets', value: '1.792' },
          ]}
        />
      </CCol>
      <CCol xs={3} >
        <CWidgetStatsD
          className="mb-3 "
          icon={<CIcon className="my-4 text-white CCol_colore" icon={cibTwitter} height={52} />}
          chart={
            <CChartLine
              className="position-absolute w-100 h-100  CCol_colorer3 "
              data={{
                labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
                datasets: [
                  {
                    backgroundColor: 'rgba(255,255,255,.1)',
                    borderColor: 'rgba(255,255,255,.55)',
                    pointHoverBackgroundColor: '#fff',
                    borderWidth: 2,
                    data: [1, 13, 9, 17, 34, 41, 38],
                    fill: true,
                  },
                ],
              }}
              options={{
                elements: {
                  line: {
                    tension: 0.4,
                  },
                  point: {
                    radius: 0,
                    hitRadius: 10,
                    hoverRadius: 4,
                    hoverBorderWidth: 3,
                  },
                },
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    display: false,
                  },
                },
                scales: {
                  x: {
                    display: false,
                  },
                  y: {
                    display: false,
                  },
                },
              }}
            />
          }
          style={{ '--cui-card-cap-bg': '#00aced' }}
          values={[
            { title: 'folowers', value: '973K' },
            { title: 'tweets', value: '1.792' },
          ]}
        />
      </CCol>
    </CRow>
    </CCarousel>
    );
};
export default Statistique;