import React,{useState, useEffect} from 'react';
import '../../css/Home/home.css' ;
import '@coreui/coreui/dist/css/coreui.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import db, {CollectionName } from '../../firebase/firebase';
import "../../css/carousel.css" ;
import {CCarousel, CCarouselItem, CImage} from '@coreui/react';
import { collection, doc, getDoc, getDocs } from 'firebase/firestore';
import { carouselData } from '../../data/data';
  const ActuCarou = () => {
    const [pubData, setpubData]=useState([]);
    useEffect(()=>{
      getPubs();
    },[])
    const getPubs= async()=>{
      const query = collection(db,CollectionName.PUB);
      try {
           const publist= await getDocs(query);
           let data= publist.docs.map(doc => doc.data()); 
           setpubData(data);     
       } catch (error) {
        setpubData(carouselData) ;
      }
    }
    //setpubData(carouselData) ;
    let publistview = pubData.map(pub=>(
        <CCarouselItem>
          <CImage className="d-block d-block-C w-100 " src={pub.image} key={pub.toString()}/>
        </CCarouselItem>
      ));
      
    return(
        <CCarousel className="carousel_container"  controls indicators dark >
          {publistview}
        </CCarousel>
    );
};
export default ActuCarou;