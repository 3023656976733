import React,{useState,useEffect} from 'react';
import '../css/vision.css' ;
import db from '../firebase/firebase';
import { CollectionName } from '../firebase/firebase';
import {query, orderBy, collection, addDoc, getDocs } from "firebase/firestore"; 
import {valeursData} from "../data/data" ;
const VisionBloc =({valeur})=>{
    
    return(<div className="valeur_container_ele" >
             <img src={valeur.image} alt='vision' className='vision_image' />
             <p className='vision_title'>{valeur.valeur}</p>
          </div>);
}


const SilmasVision = () =>{ 
    const [valeurs,SetValeurs]=useState([]);
    const getVision=async ()=>{
    const c =collection(db,CollectionName.VALEUR);
    let q= query(c,orderBy('id',"desc")) ;
    try {
    const serviceList=await getDocs(q);
      /* let data=serviceList.docs.map(doc => doc.data()) ;   
       SetValeurs(data);*/
       SetValeurs(valeursData);
    } catch (error) {
        SetValeurs(valeursData);
    }
    
   }
  
  useEffect(() => {
   getVision() ;
  }, [])
  
  //let img1 =require('../assets/pub1_in.jpeg') ;
  let listValeu =valeurs.map(e=> <VisionBloc valeur={e}/>);

return(
   <div className='vc'>  
      <h2 className='hv'>Nos valeurs </h2>
    <div className='vision_container' >
        {listValeu}      
    </div>
   </div>  
);
}

export default SilmasVision;
