import Header from '../components/Header';
import Footer from '../components/Footer';
import React,{useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import "../css/activity.css";

import {
    CContainer, 
   CRow,         
   CCol,
   CCardImage,
   CCardTitle,
   CCardText,
   CCardBody,
  } from '@coreui/react';
import '@coreui/coreui/dist/css/coreui.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import db, {CollectionName } from '../firebase/firebase';
import { collection, getDocs } from 'firebase/firestore';

const Bloc =({news})=>{  

  let img, titre, description ;
  if(news.image){     
    img= <CCardImage className="d-block d-block-C w-100 img_act" src={news.image}  animation="glow" />
  }
  titre=<CCardText>{news.titre}</CCardText>
  description = news.describe   ? <CCardText>{news.describe  }</CCardText> : <CCardText>No description available.</CCardText>;
 
  return(
      <CCol  className="new_item">
          {img}
          <CCardBody>
            <CCardTitle>
                {titre}
            </CCardTitle>  
          </CCardBody>
          {description}
      </CCol>
           )} ;
const Activity = () => {
             
  const [newData, setnewData]=useState([]);
  useEffect(()=>{
    getNew();
  },[])
  const getNew= async()=>{
    const query = collection(db,CollectionName.NEW);
    const newlist= await getDocs(query);
    let data= newlist.docs.map(doc => doc.data());
    setnewData(data);
  }
  let newlistview = newData.map(news =>(<Bloc news={news} />));
    
    return (
        <div >
          <Header/>
            <div className='dimetion_act'>
              <div className='new_container_act' > 
                  <div className='new_container_1_act' >
                    {newlistview}
                  </div>
              </div> 
            </div> 
            <Footer/>
        </div>
    );
};

export default Activity;