import React,{useEffect,useState} from 'react';
import { useParams } from 'react-router-dom';
import Header from '../components/Header';
import data from '../data' ;
import '../css/home_secon.css' ;
import '@coreui/coreui/dist/css/coreui.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  CRow,         
  CCol,
  CCardImage,
  CCardTitle,
  CCardText,
  CCardBody,
  CCard,
 } from '@coreui/react';
 //import { CIcon } from '@coreui/icons-react';
 import {CChartLine} from '@coreui/react-chartjs';
import {query, orderBy, collection, addDoc, getDocs } from "firebase/firestore"; 
import { useCollection } from 'react-firebase-hooks/firestore';
import db from '../firebase/firebase'
import { CollectionName } from '../firebase/firebase';
const Bloc =({service})=>{  

    let img ;
    if(service.image){     
      img= <CCardImage  src={service.image}/>
    }
     return(
          <>
          <CCard className="mb-3" style={{ maxWidth: '1000px',marginTop:'20px' }}>
              <CRow className="g-0">
                <CCol md={5}>
                  {img}
                </CCol>
                <CCol md={7}>
                  <CCardBody>
                    <CCardTitle>{service.title}</CCardTitle>
                    <CCardText>
                      {service.description}
                    </CCardText>
                    <CCardText>
                      <small className="text-medium-emphasis">il y a 3 mins ago</small>
                    </CCardText>
                  </CCardBody>
                </CCol>
              </CRow>
            </CCard>
         
             </>)} ;
const HomeSecond = () => {
  const [serviceData, setserviceData] = useState([]);
   useEffect(()=>{ 
     getSerices() ;   
   },[])    
    const getOrderService=async ()=>{
      const c =collection(db,CollectionName.SERVICE);
      let orderService= query(c,orderBy('date',"desc")) ;
    } 
    const getSerices=async ()=>{
         const query =collection(db,CollectionName.SERVICE);
         const serviceList= await getDocs(query);
         let data=serviceList.docs.map(doc => doc.data()) ;   
         setserviceData(data) ;                  
    }
    
  const [screenSize, getDimension] = useState({ 
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight
     });
  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight
    })
  }
  
  useEffect(() => {
    window.addEventListener('resize', setDimension);    
    return(() => {
        window.removeEventListener('resize', setDimension);
    })
  }, [screenSize])

 let width=screenSize.dynamicWidth ;
 let height=screenSize.dynamicHeight ;
  const dynamiqStyle= { 
            width:screenSize.dynamicWidth ,
            height:screenSize.dynamicHeight ,
         }
        
     
    let ListWView =serviceData.map(service=><Bloc service={service} key={1} />) ;
    //console.log('list',ListWView)
     return (
        <div style={dynamiqStyle} >
            <Header/>
            <div className='second_home_main_container'  >
                
              {ListWView}
               
            </div> 
        </div>
    );
};

export default HomeSecond;