import React,{useEffect,useState} from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { collection,doc, query, getDoc,getDocs,orderBy } from "firebase/firestore"; 
import db from '../firebase/firebase'
import { CollectionName,DocumentName } from '../firebase/firebase';
import '../css/About.css' ;
const  AboutBloc =({AboutItem})=>{
       console.log(AboutItem);
       let img ;
       if(AboutItem && AboutItem.image){
        img = <img className='icon img' src={AboutItem?AboutItem.image:''} alt='icon'/>
       }
       return(<div className='about_bloc_container'>
                {img}
                <h2 className='title' >{AboutItem?AboutItem.title:''}</h2>
                 <hr className='line'  />
               <p className='about_bloc_des' > {AboutItem?AboutItem.description:''}</p>
           </div> )
}
const About = () => {    
    const [screenSize, getDimension] = useState({ 
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight
     });
    
    const [data, setData] = useState([]);
    
    const [teamData, setteamData] = useState([{
                                               id:'1',
                                               name:'Tougma Roland',
                                               role:'Manager',
                                                des:''},
                                               {id:'2',
                                                name:'Nanema Noufou',
                                                role:'CTO',
                                                des:''  }])


    useEffect(() => {
      getSilma();
    }, [])

  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight
    })
  }
  
  useEffect(() => {    
    window.addEventListener('resize', setDimension);    
    return(() => {
        window.removeEventListener('resize', setDimension);
    })
  }, [screenSize])

 let width=screenSize.dynamicWidth ;
 let height=screenSize.dynamicHeight ;
const dynamiqStyle= { 
            width:screenSize.dynamicWidth ,
            height:screenSize.dynamicHeight ,
         }
  
     
    const getSilma =async ()=>{
        const collectionRef =collection(db, CollectionName.ABOUT);
        const q = query(collectionRef, orderBy("order", "asc"));
        const docSnap = await getDocs(q);
        let data =docSnap.docs.map(doc=>doc.data()) ;
        setData(data) ;
      }
  
   let simasView =data.map((element)=>{return <AboutBloc AboutItem={element} />})
  let teamView=teamData.map((element)=>{
                                     return(<div className='team_elemnt' key={element.id} > 
                                              <img className='team_elemnt_image' alt='img'/>
                                              <h3>{element.name}</h3>
                                              <h5>{element.role}</h5>
                                              <p>{element.des}</p>
                                     </div>)})     ;

                                         
    return (
        <div style={dynamiqStyle} className='main'>
          <Header/>
          <div className='main_bloc'>  
             <div className='main_container'>
                  {simasView}
             </div>
            
             <div  className='about_bloc about_container_team'>
                 <h1 className='title' >Notre equipe</h1>
                <div className='team_bloc' >                 
                  {teamView}
                </div>   
              </div> 
           </div> 
           <Footer/>  
        </div>
    );
};

export default About;