import React , {useState} from 'react';
import {Link} from 'react-router-dom';
import '@coreui/coreui/dist/css/coreui.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { CLink, CFooter, CAvatar } from '@coreui/react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons';
import { FaWhatsapp } from 'react-icons/fa';
import { MdMail } from 'react-icons/md';

const Footer = () => {

    const handleFacebookClick = () => {
      window.open('https://www.facebook.com/Silma1SAS', '_blank');
    };
  
    const handleWhatsappClick = () => {
      window.open('https://l.facebook.com/l.php?u=https%3A%2F%2Fapi.whatsapp.com%2Fsend%3Fphone%3D%252B22661601287%26data%3DAWClcwVgUdI4Cr4CovUp16dpY6F_PGM5OdOZXZXEolSuA3OhfHuerghqX0uz4M8fiLfp1KC_AXaMPbjAHJblHBv9X0ZKC2VfxCqED9p3fsv4moVKgglpnNb_Nfk4Bp1OZePxWbFZcbjsD4HOxsrnAXZiwgsg85ioaddowPvOw0CoAx5K4fAGrUAexm9fZ-y8kj7f6G66H8WKHiCo3nACcahAqucIqTXTGH8TofTaes9SIQRYgJFbvqTZWQbseMzIRRilg3dvChNpLadz2dW9_g%26source%3DFB_Page%26app%3Dfacebook%26entry_point%3Dpage_cta%26fbclid%3DIwAR0SJf9-QBy7F630gmxdeOZDdZs1vtNSx717I_P4jqGbS6zlaRHTJNf8V54&h=AT2fA_m79thxcd9iJkah2fL_W7x3RUaBghzFqFiher7OXSu0LKEBRMmtJiMRUx8pj5b7HRFJO3pwWut2ag1_i6cCKuoH8kzT5iqtPjl-6boaMhTPE01c3F9KGQ4mohk-IjAx0uRfFiBl_yjrl1OIZQ', '_blank');
    };
  
    const handleMailClick = () => {
      window.open('mailto:info@silma.tech', '_blank');
    };

    //size="lg"
    return (
        <CFooter style={{ backgroundColor: '#F2F2F2' }} >
          <div>
          <CAvatar src={require('../assets/SILMA-LOGO.png') } color="" size="xl"/>

          </div>
          <div>
            <Link href="https://silma.tech/">Silma.sas </Link>
            <span>&copy; 2023.</span>
          </div>
          <div>
            <FontAwesomeIcon
              icon={faFacebookSquare}
              size="2x"
              onClick={handleFacebookClick}
              color="#3B5998"
              style={{ cursor: 'pointer', verticalAlign: 'middle' }}
            />
            <FaWhatsapp
              size="2em"
              onClick={handleWhatsappClick}
              color="#25D366"
              style={{ cursor: 'pointer' }}
            />
            <MdMail
              size="2em"
              onClick={handleMailClick}
              color="#D44638"
              style={{ cursor: 'pointer' }}
            />
          </div>
        </CFooter>
    );
};
export default Footer;