const carouselData=[{image:require("../assets/rc_1.jpeg") },{image:require("../assets/rc_2.jpeg")},{image:require("../assets/rc_3.jpg")},{image:require("../assets/rc_4.jpg")},{image:require("../assets/rc_5.jpg")}] ;
const valeursData=[{image:require("../assets/v_1.jpg"),
                    valeur:"Integrité"},
                  {image:require("../assets/v_2.jpg"),
                   valeur:"Innovation" },
                  {image:require("../assets/v_3.jpg"),
                   valeur:"Formation" },
                  {image:require("../assets/v_5.png"),
                   valeur:"Excellence" }]
export {carouselData};
export {valeursData} ;