import React, { useEffect } from 'react';
import Header from './Header';
import "../css/galeri.css" ;
import Footer from './Footer';
import { useState } from 'react';
import { async } from '@firebase/util';
import db from '../firebase/firebase';
import {query, orderBy, collection, addDoc, getDocs } from "firebase/firestore"; 
import { CollectionName } from '../firebase/firebase';
const Galeri=({item}) =>{

    return(
        <div className='image_container'>
               <img className='image_item' src={item.image} />
               <h4>{item.titre}</h4>
        </div>
    )
}
const Galerie = () => {
    const [galeries, setgaleries] = useState([]);
    useEffect(()=>{ 
             getImage();
       },[])
    
   const getImage=async()=>{
            const c =collection(db,CollectionName.GALERIES);
            let q= query(c) ;
            const serviceList= await getDocs(q);
            let data=serviceList.docs.map(doc => doc.data()) ;
            console.log(data);
            if (data) {
                setgaleries(data);
            } 
         }
const listImage =galeries.map(element=> <Galeri item={element}/>)
    return (
        <div>
            <Header/>
            <div className='g_container' >
               {listImage }
            </div> 
            <Footer/>
        </div>
    );
};

export default Galerie;