
import './App.css';
import {
	BrowserRouter,
	Routes,
	Route,
} from 'react-router-dom';


import Home from './pages/Home';
import silmasRoute from './constants/SilmasRoutes' ;
import About from './pages/About';
import HomeSecond from './pages/HomeSecond';
import Domains from './pages/Domains';
import Activity from './pages/Activity';
import Contact from './pages/Contact';
import Galerie from './components/Galerie';
const App=()=>{
  return (
     
      <Routes>
        <Route>
            <Route exact path="/" element={<Home/>} />
            <Route exact path="/home" element={<HomeSecond/>} />
            <Route exact path={silmasRoute.about} element={<About/>} />
            <Route exact path={silmasRoute.galeries} element={<Galerie/>} />
            <Route exact path={silmasRoute.contact} element={<Contact/>} />
            <Route exact path={silmasRoute.silmas_domain} element={<HomeSecond/>} />
            <Route exact path={silmasRoute.activity} element={<Activity/>} />
           
        </Route>
      </Routes>
    
  );
}

export default App;
