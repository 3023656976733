import React , {useState} from 'react';
import {Link} from 'react-router-dom';
import '../styles.css';
import"../css/hearder_1.css" ;
import '@coreui/coreui/dist/css/coreui.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import silmasRoute from '../constants/SilmasRoutes';
import {CNavbar,
        CNavbarToggler,
        COffcanvas,
        COffcanvasHeader,
        COffcanvasTitle,
        CCloseButton,
        COffcanvasBody,
        CNavbarNav,
        CNavItem,
        CNavLink,
        CContainer,
        CRow,
        CCol
        } from '@coreui/react';
const Header = () => {
    const [indicator, setindicator] = useState(1) ;
    const header_second_bloc={
        display:'flex',
        flexDirection:'column'
    }
    const focus ={backgrounColor:''}
   
    const [visible, setVisible] = useState(false)
    return (  
        <div className='header_items'>   

        <CRow className="justify-content-between">
            <CNavbar color='red' colorScheme="light" className="bg-light header w-100" expand="xxl" placement="fixed-top" >
                <CCol>
                    <div className='header_block_on' >
                        <Link to={'/'}>
                            <img src={require('../assets/SILMA-LOGO.png') } className='logo' alt='logo' /> 
                        </Link>
                         <h2 className='header-des slogan'>Silma.sas </h2> 
                    </div>  
                </CCol>
                
                <CCol xs={1} className="header_list1 top-0 end-0 ">
                <div xs = " auto " className=' d-flex' placement="end"  > 
                <CContainer fluid className='align-self-end'>
                    <CNavbarToggler
                        placeholder = " Search "
                        aria-controls="offcanvasNavbar2"
                        aria-label="Toggle navigation"
                        onClick={() => setVisible(!visible)}
                       />
                    <COffcanvas id="offcanvasNavbar2" placement="end" portal={false} visible={visible} onHide={() => setVisible(false)}>
                        <COffcanvasHeader>
                            <COffcanvasTitle>Menu</COffcanvasTitle>
                            <CCloseButton className="text-reset" onClick={() => setVisible(false)} />
                            </COffcanvasHeader>
                                <COffcanvasBody>
                                <CNavbarNav>
                                    <CNavItem>
                                        <CNavLink  active>
                                            <Link to={'/'} className='header_link_button' >
                                                Acceuil
                                            </Link>
                                        </CNavLink>
                                    </CNavItem>
                                    <CNavItem>
                                        <CNavLink>
                                            <Link to={silmasRoute.silmas_domain} className='header_link_button' >
                                                Domaine d'intervention
                                            </Link>
                                        </CNavLink>
                                    </CNavItem>
                                  
                                   
                                    <CNavItem>
                                        <CNavLink>
                                            <Link to={silmasRoute.contact} className='header_link_button'>
                                                Contacts
                                            </Link>
                                        </CNavLink>
                                    </CNavItem>
                                    <CNavItem>
                                        <CNavLink>
                                            <Link to={silmasRoute.galeries} className='header_link_button'>
                                                Galeries
                                            </Link>
                                        </CNavLink>
                                    </CNavItem>
                                </CNavbarNav>
                        </COffcanvasBody>
                    </COffcanvas>
                </CContainer>
            </div>
        </CCol>
        </CNavbar>
    </CRow>  
    </div>          
    );
};
export default Header;