import React,{useState,useEffect} from 'react';
import Header from '../components/Header';
import '../css/contact.css' ;
import Footer from '../components/Footer';
import {Link} from 'react-router-dom';

const Contact = () => {
  const [screenSize, getDimension] = useState({ 
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight
     });
     const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight
    })
  }
  
  useEffect(() => {    
    window.addEventListener('resize', setDimension);    
    return(() => {
        window.removeEventListener('resize', setDimension);
    })
  }, [screenSize])

 let width=screenSize.dynamicWidth ;
 let height=screenSize.dynamicHeight ;
const dynamiqStyle= { 
            width:screenSize.dynamicWidth ,
            height:screenSize.dynamicHeight ,
         } 
    let logo=require('../assets/SILMA-LOGO.png')
    return (
        <div style={dynamiqStyle}>
            <Header/>
            <div className='contact_container' style={{marginTop: '200px'}} >
              <div className='contact_container1' >
                     <div className="contact_bloc"> 
                         <img src={require("../assets/contact.jpg")} className="icon_contact" />
                         <h6>Adresse</h6>
                         <h2>OUAGADOUGOU,Tampui</h2>
                     </div>
                     <div className="contact_bloc">
                         <img src={require("../assets/mail.jpg")} className="icon_contact" />
                         <h6>Mail</h6>
                         <h2>info@silma.tech</h2>
                      </div>
                     <div className="contact_bloc"> 
                         <img src={require("../assets/position.png")} className="icon_contact" />
                         <h6>Telephone</h6>
                         <h2>+226 61601298</h2>
                     </div>
              </div>
              <div className='formular_container'>
                  
              </div>     
            </div> 
            <Footer style={{marginTop: '200px'}} className="align-items-end"/>
        </div>
    );
};

export default Contact;