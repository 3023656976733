
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
const firebaseConfig = {
  apiKey: "AIzaSyDMwBMLT_stR-qAJDLuSoy8Mknl3poC8K8",
  authDomain: "silmas-e03d3.firebaseapp.com",
  projectId: "silmas-e03d3",
  storageBucket: "silmas-e03d3.appspot.com",
  messagingSenderId: "750674056561",
  appId: "1:750674056561:web:a08d3b8cfe0921cc9152d0",
  measurementId: "G-R2JWQVNESV"
};
const CollectionName={
  SERVICE:'SERVICES',
  ABOUT:'ABOUT',
  PUB:'PUB',
  NEW:'NEW',
 SOLUTIONS:'SOLUTIONS' ,
 VALEUR:"VALEURS",
 GALERIES:"GALERIES",

}
const DocumentName={
  notreVision:'t1vZJiIYqLfZs4OtHWE8' ,
}
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);

export {CollectionName,DocumentName} ;
export default db ;